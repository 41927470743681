
  const data = [
    {
      title: 'Seismic Data and Seismic Design Criteria',
      questions: [
        {key:'a1',
        description:'Determine Site Class #1',
        question:"For Site Class A, which methods are used to determine the site Class?",
        false1:'Average field standard penetration resistance test  and, average standard penetration resistance test for cohesionless soil layers',
        false2:'Average undrained shear strength',
        false3: 'Any of the three methods',
        answer:'Average shear wave velocity',
        solution:"ASCE 7-16 chapter 20.3.5 <br> The hard rock, Site Class A, category shall be supported by the shear wave velocity measurement either on site or on profiles of the same rock type in the same formation with an equal or higher degree of weathering and fracturing.",
        lectures:'2',
        difficulty:'easy'},
        {key:'a2',
        description:'Determine Site Class #2',
        question:"If a site profile contains a 10ft thick layer of very moist soil (moisture content = 50%) in the upper 100ft, and the result for the average shear wave velocity is 800 ft/s. What is the appropriate site class?",
        false1:'F',
        false2:'C',
        false3: 'D',
        answer:'E',
        solution:"ASCE 7-16 Table 20.3-1 <br> Any soil profile with more than 10 ft of soil with a moisture content ≥ 40% should be classified as class E.",
        lectures:'2',
        difficulty:'easy'},
        {key:'a3',
        description:'Site Coefficients #1',
        question:"For a Site Class B with no site-specific velocity measurements, what should be the considered values for the site coefficients Fa and Fv?",
        false1:'0.75',
        false2:'1.25',
        false3: '0',
        answer:'1',
        solution:"ASCE 7-16 chapter 11.4.3 <br> For conditions consistent with Site Class B, but site-specific velocity measurements are not made, the site coefficients F\\(_{a}\\), F\\(_{v}\\), and F\\(_{pga}\\) shall be taken as unity (1.0).",
        lectures:'1, 3, 8',
        difficulty:'easy'},
        {key:'a4',
        description:'Importance Factor #1',
        question:"What is the seismic importance factor for a veterinary clinic that performs surgeries on animals?",
        false1:'Not enough information available',
        false2:'1.0',
        false3: '1.25',
        answer:'1.5',
        solution:"Per ASCE 7-16 Table 1.5-1, there is no specific category for animal hospitals or veterinary clinics. Therefore the default category would be Risk Category 2. Per Table 1.5-2 a Risk Category 2 structure has a seismic importance factor of 1.0. An importyance factor of 1.5 would be applicable if it was a medical facility that provided surgery on human patients.",
        lectures:'5',
        difficulty:'easy'},
        {key:'a5',
        description:'Seismic Design Category #1',
        question:"What is the Seismic Design Category of a single story residential home if S\\(_{s}\\)=0.75 and the average field standard penetration result is 75 blows/ft?",
        false1:'A',
        false2:'B',
        false3: 'C',
        answer:'D',
        solution:"Using Table 20.30-1 with 75 blows/ft corresponds to Site Class C. <br> Fa = 1.2 per Table 11.4-1 <br> SMS = FaSs (EQ 11.4-1) <br> SMS = (1.2)(0.75) = 0.9 <br> SDS=2/3SMS (EQ 11.4-1) <br> SDS=2/3(0.9) = 0.6 <br> Residential Home is Risk Category II per Table 1.5-1 <br> With these inputs, reading Table 11.6-1 the Seismic Design Category is D",
        lectures:'5',
        difficulty:'medium'},
        {key:'a6',
        description:'Seismic Design Category #2',
        question:"What is the SDC of a Nuclear Power plant with an SD1 of 0.15? Assume S1 < 0.75.",
        false1:'A',
        false2:'B',
        false3: 'C',
        answer:'D',
        solution:"Using Table 1.5-1, a Nuclear Power Plant has a Risk Category IV <br> Per Table 11.6-2, A RC IV and SD1 in between 0.13 3 and 0.20 shall be type D. <br> Note that if we were provided an S1 value exceeding 0.75, per the description in 11.6 the SDC for a RC IV structure with S1 > 0.75 would be type F.",
        lectures:'5',
        difficulty:'easy'},
        {key:'a7',
        description:'Exceptions #1',
        question:"How can a structure on Site Class E with Ss = 1.2 be designed without the need of a ground motion hazard analysis?",
        false1:'The proposed structure requires a ground motion hazard analysis.',
        false2:'Use a T greater than Ts.',
        false3: 'Double the short period design acceleration parameter.',
        answer:'Determine short period acceleration parameters assuming site class C.',
        solution:"Exception #1 of section 11.4.8 states that a ground motion hazard analysis is not required for the structure type if the site coefficient Fa is taken as equal to that of site class C. <br> It is also acceptable to forego a ground motion hazard analysis for the proposed structure if T < Ts per exception #3.",
        lectures:'5',
        difficulty:'easy'},
        {key:'a8',
        description:'Importance Factor #2',
        question:"Importance factor is related to all of the followinge except:",
        false1:'The degree of risk to human life if the structure fails',
        false2:'The type of structure occupancy or use',
        false3: 'Risk Category',
        answer:'The time to repair the structure after it is damaged',
        solution:"Importance Factors are determined in Table 1.5-2, and are based on Risk Category. Risk Category is influenced by the degree of risk to human life if the structure fails, and also the type of structure occupancy. <br> The only factor listed that does not directly influence Importance Factor is the time to repair the structure. Performance Based Design can be used to set building requirements based on repair times, however this is not a part of determining Importance Factors.",
        lectures:'2',
        difficulty:'easy'},
      ]
    },
    {
      title: 'Seismic Characteristics of Engineered Systems',
      questions: [
        {key:'b1',
        description:'Irregularity #1',
        question:"Which vertical irregularity is present in the following building structure?",
        false1:'Stiffness-Soft Story Irregularity',
        false2:'Vertical Geometric Irregularity',
        false3: 'In-Plance Discontinuity',
        answer:'Weight Irregularity',
        solution:"Per Table 12.3-2, type 2: Weight Irregularity <br> Any non-roof level that is more than 150% of an adjacent level is considered to have a mass irregularity. <br> Since W3 is more than 150% of W2, this condition is met. <br> Note that W4 is a roof level, so it does not trigger the irregularity. ",
        lectures:'13',
        difficulty:'easy'},
        {key:'b2',
        description:'Analytical Procedures #1',
        question:"Which of the following procedures is not allowed for a 170 ft tall steel moment frame building in Seismic Design Category D with a fundamental building period of 4 seconds?",
        false1:'Modal Response Spectrum Analysis',
        false2:'Nonlinear Response History Procedures',
        false3: 'None of the procedures are permitted',
        answer:'Equivalent Lateral Force Procedure',
        solution:"Per Table 12.6-1, the described structure would fall under 'All Other Structures' category because it does not fit into any other characteristics for SDC D. If the height was shorter (less than 160 ft) than all procedures would be permitted.",
        lectures:'13',
        difficulty:'easy'},
        {key:'b3',
        description:'Damping Ratio #1',
        question:"What damping ratio is used for creating the design spectral response acceleration parameters found in ASCE 7?",
        false1:'3%',
        false2:'4%',
        false3: '10%',
        answer:'5%',
        solution:"The definitions of Sds and Sd1 in section 11.3 explicitly state they are 5% damped accerlation parameters. Therefore the answer is 5%.",
        lectures:'4, 6',
        difficulty:'easy'},
        {key:'b4',
        description:'Damping Ratio #2',
        question:"The damping ratio is the ratio of:",
        false1:'Maximum lateral drift to the total building height',
        false2:'The number of building oscillations per second',
        false3: 'Undamped frequency to damped frequency',
        answer:'The actual damping to the critical damping',
        solution:"Critical damping is the amount of damping required so that a system returns to rest from a starting position without oscillating. The damping ratio is a ratio of the actual damping to this critical damping. A damping ratio of 0 would indicate that the structure is undamped and would never stop oscillating.",
        lectures:'6',
        difficulty:'easy'},
        {key:'b5',
        description:'Height Limitations #1',
        question:"What is the structure height limitation for a building reinforced with steel special truss moment frames and a Seismic Design Category E?",
        false1:'80 ft',
        false2:'No Limit',
        false3: '160 ft',
        answer:'100 ft',
        solution:"Per Table 12.2-1 under the section C, a steel special truss moment frame with SDC E is limited to 100 ft. ",
        lectures:'7',
        difficulty:'easy'},
        {key:'b6',
        description:'Lateral System Requirements #1',
        question:"For a Shear Wall-Frame Interactive System, what minimum percentage of the design story shear must the frames be capable of resisting?",
        false1:'75%',
        false2:'100%',
        false3: '50%',
        answer:'25%',
        solution:"Per section 12.2.5.8, frames in Shear Wall-Frame Interactive Systems must be able to withstand 25% of the story shear forces.",
        lectures:'7',
        difficulty:'easy'},
      ]
    },
    {
      title: 'Seismic Forces: Building Systems',
      questions: [
        {key:'c1',
        description:'Ductility #1',
        question:"What does a larger R value mean for the ductility of a lateral system and a structure's seismic base shear?",
        false1:'Greater ductility and smaller reduction to seismic base shear',
        false2:'Smaller ductility and smaller reduction to seismic base shear',
        false3: 'Smaller ductility and greater reduction to seismic base shear',
        answer:'Greater ductility and greater reduction to seismic base shear',
        solution:"The response modification factor (R) is a way of expressing the ductility of a lateral system. Therefore a larger R value implies greater ductility. <br> Per equation 12.8-1, seismic base shear is dependent on Cs (V=CsW). <br> Although there are multiple ways to calculate the Cs value, the R value is always in the denominator and therefore a larger R value means smaller Cs value. Therefore a larger R value creates a greater reduction to seismic base shear. ",
        lectures:'7',
        difficulty:'easy'},
        {key:'c2',
        description:'Ductility #2',
        question:"A client gives you the following list below. Determine which lateral system would produce the largest seismic base shear on the structure assuming the system does not impact the building mass. ",
        false1:'Steel composite ordinary braced frames',
        false2:'Ordinary reinforced concrete shear walls',
        false3: 'Steel ordinary shear walls',
        answer:'Steel ordinary cantilever column system',
        solution:"Seismic base shear is determined via Equation 12.8-1 (V=CsW). Since Cs varies inversely with R, a smaller R increases the base shear. Therefore, we need to look at which system has the smallest R value. <br> Per table 12.2-1 the smallest R value corresponds to the steel ordinary cantilever column system.",
        lectures:'7',
        difficulty:'easy'},
        {key:'c3',
        description:'Response Coefficient #1',
        question:"Calculate the seismic response coefficient (Cs) for a hospital with ordinary reinforced concrete shear walls with a SDS value of 0.35.",
        false1:'0.2',
        false2:'0.15',
        false3: '0.05',
        answer:'0.10',
        solution:"To calculate the Cs value, use equation 12.8-2 <br> Cs=SDS/(R/Ie). <br> Use table 12.2-1 to find that R = 5.5. <br> Hospitals are risk category IV per table 1.5-1. Risk Category IV structures have a seismic importance factor Ie = 1.5 per table 1.5-2. <br> Along with the given SDS value, we can now plug in all values and solve. <br> Cs=SDS/(R/Ie) <br> Cs=0.35/(5.5/1.5) <br> Cs=0.1",
        lectures:'5, 7, 8',
        difficulty:'medium'},
        {key:'c4',
        description:'Base Shear #1',
        question:"What is the base shear for a building structure with seismic response coefficient of 0.1 and effective seismic weight to of 20,000 kips?",
        false1:'1000 kip',
        false2:'3000 kip',
        false3: '4000 kip',
        answer:'2000 kip',
        solution:"Recall that the seismic response coefficient is Cs. Per equation 12.8-1, the base shear is V = CsW. The Cs value was given to be 0.1 and the given W value is 20,000 kips. <br> V=CsW <br> V = (0.1)(20000 kip) <br> V = 2000 kips",
        lectures:'8',
        difficulty:'easy'},
        {key:'c5',
        description:'Seismic Weight #1',
        question:"Which types of live loads can be included when calculating effective seismic weight? <br> <br> I: Operating weight of permanent equipment <br> II: Roof garden materials <br> III: Dinner tables <br> IV: Assembly area live loads that meet or exceed 100 psf",
        false1:'I',
        false2:'I, II',
        false3: 'IV',
        answer:'I, II, III',
        solution:"According to section 12.7.2, the effective seismic weight shall include the dead load and the additional loads as specified in 12.7.2. The only load from the problem statement not listed in 12.7.2 is assembly area live loads. Therefore the best answer is I, II, and III.",
        lectures:'8',
        difficulty:'easy'},
        {key:'c6',
        description:'Base Shear #2',
        question:"What most accurately desccribes seismic base shear?",
        false1: "The total vertical force resisted through a structure's lateral resisting system",
        false2:"A product of the division between the effective seismic weight and the building period.",
        false3: 'A product of the multiplication between effective seismic weight and the response modification factor',
        answer:"The total horizontal force resisted by a structure's lateral resisting system",
        solution:"Seismic base shear is explicitly defined in section 12.8.1 as the product of multiplication between a response coefficient and the effective seismic weight. Only some equations for response coeffient require division by building period, so that answer is not accurate in all situations. In addition, the base shear is the result of division between effective seismic weight and the response modification factor, not multiplication. <br> <br> Section 12.4.2.1 explicitly denotes Base Shear (V) as a horizontal force, therefore denoting Base Shear as a total horizontal force resisted by the lateral system is the most accurate option <br> <br> Lastly, vertical seismic forces are defined in 12.4.2.2 and are not the same as base shear forces. ",
        lectures:'8',
        difficulty:'easy'},
        {key:'c7',
        description:'Force Distribution #1',
        question:"For the purpose of determining seismic vertical force distribution, where along a structure is mass lumped?",
        false1: "At the roof",
        false2:"At the ground floor",
        false3: 'Halfway between each floor',
        answer:"At each diaphragm",
        solution:"Vertical distribution of seismic forces is described in section 12.8.3. The total base shear is distributed vertically at levels. These levels are referred to in section 12.10.1 for diaphragm design, and each level is essentially a structural diaphragm. Therefore the best answer is at each diaphragm.",
        lectures:'8',
        difficulty:'easy'},
        {key:'c8',
        description:'Seismic Weight #2',
        question:"What is the effective seismic weight at the second level of a three story building with the following characteristics: <br> <br> Plan Dimensions: 60 ft x 25 ft <br> Typical Story Height: 15 ft <br> Typical Floor Self Weight: 100 psf <br> Typical Wall Self Weight: 20 psf",
        false1: "175 kip",
        false2:"150 kip",
        false3: '51 kip',
        answer:"201 kip",
        solution:"Step 1: Typical floor area = (60ft)(25ft) = 1500 ft\\(^{2}\\) <br> Step 2: Typical floor weight = (100psf)(150ft\\(^{2}\\) = 150 kip <br> Step 3: Typical wall area = Perimeter x Tributary Height = (60ft + 25ft)(2)(15ft) = 2550 ft\\(^{2}\\) <br> Step 4: Typical wall weight per story = (2550 ft\\(^{2}\\))(20 psf) = 51 kip <br> Step 5: 2nd level effective seismic weight = 150 kip + 51 kip = 201 kip",
        lectures:'8',
        difficulty:'medium'},
        {key:'c9',
        description:'Seismic Weight #3',
        question:"What is the effective seismic weight at the roof level of a three story building with the following characteristics: <br> <br> Plan Dimensions: 60 ft x 25 ft <br> Typical Story Height: 15 ft <br> Typical Floor Self Weight: 100 psf <br> Typical Wall Self Weight: 20 psf",
        false1: "201 kip",
        false2:"150 kip",
        false3: '51 kip',
        answer:"176 kip",
        solution:"Step 1: Typical floor area = (60ft)(25ft) = 1500 ft\\(^{2}\\) <br> Step 2: Typical floor weight = (100psf)(150ft\\(^{2}\\) = 150 kip <br> Step 3: Wall Area tributary to roof = Perimeter x Tributary Height = (60ft + 25ft)(2)(7.5ft) = 1275 ft\\(^{2}\\) <br> Step 4: Typical wall weight per story = (1275 ft\\(^{2}\\))(20 psf) = 25.5 kip <br> Step 5: 2nd level effective seismic weight = 150 kip + 25.5 kip = 176 kip",
        lectures:'8',
        difficulty:'medium'},
        {key:'c10',
        description:'Fundamental Period #1',
        question:"Determine the approximate fundamental period T\\(_{a}\\) of a building with Steel Moment-Resisting Frames with S\\(_{D1}\\) = 0.2 and H = 40 ft",
        false1: "0.125 s",
        false2:"0.4 s",
        false3: '0.8 s',
        answer:"0.54 s",
        solution:"Equation 12.8.7: T\\(_{a}\\) =  C\\(_{t}\\)h\\(_{n}\\)\\(^{x}\\) <br> Steel Moment-Resisting Frames C\\(_{t}\\) = 0.028 and x = 0.8 from table 12.8-2 <br> T\\(_{a}\\) = (0.028)(40\\(^{0.8}\\)) = 0.54 s",
        lectures:'8',
        difficulty:'easy'},
      ]
    },
    {
      title: 'Seismic Analysis Procedures',
      questions: [
        {key:'e1',
        description:'Analysis Procedure #1',
        question:"Which of the following procedures is not allowed for a 170' tall steel moment frame building in Seismic Design Category D with a fundamental building period of 4 seconds?",
        false1:'Modal Response Spectrum Analysis',
        false2:'Nonlinear Response History Procedures',
        false3: 'All procedures are allowed',
        answer:'Equivalent Lateral Force Procedure',
        solution:"Reference ASCE 7-16 Table 12.6-1 Permitted Analytical Procedures. The described structure would fall under the “All Other Structures” category because it does not fit into any of the structural characteristics for SDC D.",
        imgQ:"e1Q.png",
        imgS:"e1S.png",
        lectures:'3',
        difficulty:'easy'},
        {key:'e2',
        description:'Max building height #1',
        question:"What is the structure height limitation for a building reinforced with steel special truss moment frames and a Seismic Design Category E?",
        false1:'160 ft',
        false2:'No Limit',
        false3: 'Not Permitted',
        answer:'100 ft',
        solution:"ASCE 7-16 Table 12.2-1 <br> Design Coefficients and Factors for Seismic Force-Resisting Systems Structural System Limitations Including Structural Height <br> h\\(_{n}\\) (ft) \\(Limits^{d}\\)",
        lectures:'1, 3, 8',
        difficulty:'easy'},
        {key:'e3',
        description:'Analysis Procedure #2',
        question:"Determine the rigidity of a concrete-filled metal deck diaphragm with a span to depth ratio of 2 in a structure with no irregularities. The deflection of the diaphragm is 2 inches and the drift is equal to 1.5 inches.",
        false1:'More information is required to determine rigidity',
        false2:'Flexible',
        false3: 'Semi-Rigid',
        answer:'Rigid',
        solution:"ASCE 7-16 section 12.3.1.2 Rigid Diaphragm Condition. <br> Diaphragms of concrete slabs or concrete-filled metal deck with span-to-depth ratios of 3 or less in structures that have no horizontal irregularities are permitted to be idealized as rigid.",
        lectures:'12',
        difficulty:'easy'},
      ]
    }
  ];


module.exports = {data};